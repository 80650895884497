<template>
    <div></div>
</template>
<script>

import { Component, Vue } from 'vue-property-decorator';
import store from '@/store/store';

@Component
export default class AdministratorMain extends Vue {

    beforeRouteEnter(to, from, next) {
        store.commit('setBreadcrumbs', [{
            text: 'Administration',
            to: '/admin/main',
            active: true
        }]);

        next();
    }
}
</script>
<style scoped>

</style>
